import { motion } from "framer-motion";
import React from "react";
import { BiCategory } from "react-icons/bi";

export default function Values() {
  return (
    <>
    <h1 className="our-values">
      Our Values
    </h1>
    <div className="main-value">
      <div className="main-values-circle"></div>
      <div className="values-container">
        <motion.div
          className="value-box"
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <div className="values-circle">
            <p>
              <BiCategory />
            </p>
          </div>
          <h2>Integrity</h2>
          <p>
            Integrity is our North Star at Dars for Youth. It's about being
            honest, transparent, and building trust. We believe in doing what's
            right, ensuring our actions speak louder than words. With Integrity
            as our guide, we navigate a path of credibility and accountability,
            making a lasting impact on the Madrasa Community.
          </p>
        </motion.div>

        <motion.div
          className="value-box"
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <div className="values-circle">
            <p>
              <BiCategory />
            </p>
          </div>
          <h2>Innovation</h2>
          <p>
            Innovation is the spark that lights up Dars for Youth. We love
            thinking outside the box, finding fresh ways to empower the Madrasa
            Community. Our creative spirit keeps us on the cutting edge,
            exploring exciting approaches to skill development. With Innovation,
            we promise a dynamic journey, making a difference that truly
            matters.
          </p>
        </motion.div>
        <motion.div
          className="value-box"
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <div className="values-circle">
            <p>
              <BiCategory />
            </p>
          </div>
          <h2>Impact</h2>
          <p>
            At Dars for Youth, Impact is our heartbeat. We don't just count
            numbers; we create real change. Every effort, big or small, echoes
            with purpose. Our goal is to leave a lasting imprint on the lives of
            the Madrasa Community, making a positive difference that ripples
            through generations.
          </p>
        </motion.div>
        <motion.div
          className="value-box"
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <div className="values-circle">
            <p>
              <BiCategory />
            </p>
          </div>
          <h2>Empowerment</h2>
          <p>
            Empowerment is our mission's heartbeat at Dars for Youth. We're on a
            mission to uplift and equip the Madrasa Community, unlocking their
            incredible potential. It's more than education; it's about building
            a culture where individuals stand tall, ready to shape their own
            destinies and contribute meaningfully to the world. Join us on this
            empowering journey!
          </p>
        </motion.div>
      </div>
    </div>
    </>
  );
}
