import React from "react";

import sdg from '../../data/Sustainable_Development_Goal_4.png';

export default function Sdg() {
  return (
    <div className="sdg-main">
      <div>
        <img  alt="" src={sdg} />
      </div>
      <div>
        <h1>
          Our Contribution to SDG
          <br></br>
          <span>
          Empowering Minds, Transforming Futures
          </span>
        </h1>

        <p>
          At Dars for Youth, we fervently contribute to SDG 4 by equipping
          Madrasa students with diverse skills, crafting a pathway to Quality
          Education. Our dedication lies in cultivating a brighter future
          through holistic learning, empowering young minds to flourish and
          contribute meaningfully to society. Join us in crafting a world where
          education inspires boundless possibilities and metamorphoses lives.
        </p>
      </div>
    </div>
  );
}
