import React from "react";
import { IoIosClose } from "react-icons/io";
import { NavLink } from "react-router-dom";

export default function NavbarModal(props) {
  const { handleMenuClose } = props;
  //  const [activeMenu, setActicveMenu] = useState("home")

  return (
    <div className="md-screen-modal">
      <div className="md-screen-modal-container">
        <div className="croxx-modal" onClick={handleMenuClose}>
          <h2>
            <IoIosClose />
          </h2>
        </div>

        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
              onClick={handleMenuClose}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/blogs"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
              onClick={handleMenuClose}
            >
              Blogs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/programs"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
              onClick={handleMenuClose}
            >
              Programs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/team"
              className={({ isActive, isPending }) =>
                isActive ? "active" : ""
              }
              onClick={handleMenuClose}
            >
              Our People
            </NavLink>
          </li>
          <li>
            <a
              href="https://forms.gle/Hku73dfVmtm5tTyv8"
              className="active"
              onClick={handleMenuClose}
              target="blank"
            >
              Join Us
            </a>
          </li>
          {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLScQOEc-GrhgabbefChJHr-0Teuz7Ds1mAX3mtZ_h8aQtsELGA/viewform">
            Join Us
          </a> */}
        </ul>
      </div>
    </div>
  );
}
