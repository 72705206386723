
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { db } from "../firebaseConfigue";

export default function useTotalBlogs() {

    const [totalArticle, setTotalArticle] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const articleRef = collection(db, "Blogs");
      const q = query(articleRef, orderBy("createdAt", "desc"));
      onSnapshot(q, (snapshot) => {
        const article = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTotalArticle(article);
        setLoading(false);
      });
    }, []);
  
  return {totalArticle , loading}
}
