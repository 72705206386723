import React from "react";
import "./Hero.css";
import Slider from "./Slider";

export default function Hero() {
  return (
    <div className="holder-hero">
      {/* <div className="hero-text">
        <img src={heroline} alt="" />
        <div className="hero-text-container">
          <h1 className="jj">Dars For Youth</h1>
          <h3>Bringing Change to Bring Revolution</h3>
          <h3> - Rabbani</h3>
        </div>
      </div> */}

      <div className="panel">
        <h1 className="jj">
          <span>Dars For Youth</span>
        </h1>
        <h1 className="jj">
          <span>Dars For Youth</span>
        </h1>

        <div className="other-content">
          <h3>Transforming Madrasah Community</h3>
          {/* <h4>-Rabbani</h4> */}
        </div>
      </div>
      <div>
        <Slider />
      </div>
    </div>
  );
}
