import React from "react";
import { Link } from "react-router-dom";
import "./Blog.css";

export default function Blog(props) {
  const { imgUrl, createdAt, desc,id } = props.value;

  return (
    <div className="blog-cointainer">
      <div className="blog-image-container">
        <img alt="blog-img" src={imgUrl} />
      </div>
      <div className="blog-details-container">
        <h4>{desc.slice(0, 20)} ...</h4>
        <p>
          {createdAt.toDate().toDateString()}
          <span>
            <Link to={`/blogs/${id}`}>
            <button>View</button>
            </Link>
          </span>
        </p>
      </div>
    </div>
  );
}
