import React from 'react'
import HomeComp from '../components/homes/HomeComp'
import Mission from '../components/homes/Mission'
import Values from '../components/homes/Values'
import transition from '../transition'
import FounderMsg from '../components/homes/FounderMsg'
import HomeBlogs from '../components/homes/HomeBlogs'
import Hero from '../components/entry/Hero'
import Sdg from '../components/homes/Sdg'


function Home() {
  return (
    <div>
      <Hero />
      <HomeComp />
      <Values />
      <Mission />
      <FounderMsg />
      <Sdg />
      <HomeBlogs />
    </div>
  )
}

export default transition(Home)

