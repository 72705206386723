import img1 from "../data/Cover 1.jpg";
import img4 from '../data/Cover 2.JPG';
import img2 from "../data/IMG-20230321-WA0158.jpg";
import img3 from '../data/MIH_9838 (1).jpg';
import transition from "../transition";

function Programs() {
  return (
    <div className="program-main">
      <div className="program-content">
        <h1>Dars of Career</h1>
        <p>
          "Dars of Career" is our beacon of opportunity for Madrasa Students. In
          this empowering program, we focus on nurturing essential career
          skills, guiding them towards a purposeful and rewarding professional
          journey. With two successful seasons behind us, we're dedicated to
          unlocking the potential of every student, building not just careers
          but dreams. Join us on this motivational path of discovery and growth.
        </p>
      </div>

      <div className="programs-image-container">
        <div className="program-image-card">
            <img  alt="" src={img1} />
        </div>
        <div  className="program-image-card">
            <img alt="" src={img2} />
        </div>
      </div>
      <div className="programs-image-container">
        <div className="program-image-card">
            <img  alt="" src={img3} />
        </div>
        <div  className="program-image-card">
            <img alt="" src={img4} />
        </div>
      </div>
    </div>
  );
}

export default transition(Programs);
