import { AnimatePresence } from "framer-motion";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import DarsFounder from "./components/team/DarsFounder";
import About from "./pages/About";
import Blogs from "./pages/Blogs";
import Home from "./pages/Home";
import Programs from "./pages/Programs";
import Team from "./pages/Team";
import SingleBlog from "./components/BlogComp/SingleBlog";
import EmpolyeeForm from "./pages/EmpolyeeForm";
import Faq from "./pages/Faq";

import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/programs" element={<Programs />}></Route>
          <Route path="/blogs" element={<Blogs />}></Route>
          <Route path="/team" element={<Team />}></Route>
          <Route path="/Rifat-Rabbani" element={<DarsFounder />}></Route>
          <Route path="/joindars" element={<EmpolyeeForm />}></Route>
          <Route path="/blogs/:id" element={<SingleBlog />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
        </Routes>
        <Footer />
      </AnimatePresence>
    </BrowserRouter>
  );
}

export default App;
