import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";


// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./Hero.css";

import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";

import dars2 from "../../data/3.jpg";
import dars3 from "../../data/5.jpg";
import dars4 from "../../data/8.jpg";
import dars5 from "../../data/9.jpg";
import dars1 from '../../data/rabbaniSlider.png'

export default function Slider() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          //   disableOnInteraction: false,
        }}
        // pagination={{
        //   clickable: true,
        // }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        effect="fade"
        className="my-slider"
      >
        <SwiperSlide className="slider">
          <div className="each-slider-box">
            <img alt="" src={dars5} />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider">
          <div className="each-slider-box">
            <img alt="" src={dars2} />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider">
          <div className="each-slider-box">
            <img alt="" src={dars1} />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider">
          <div className="each-slider-box">
            <img alt="" src={dars4} />
          </div>
        </SwiperSlide>

        <SwiperSlide className="slider">
          <div className="each-slider-box">
            <img alt="" src={dars3} />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
