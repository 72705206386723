
import { collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from 'react';
import { db } from "../firebaseConfigue";

export default function useBlog() {

    const [article, setArticle] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const articleRef = collection(db, "Blogs");
      const q = query(articleRef, orderBy("createdAt", "desc") , limit(4));
      onSnapshot(q, (snapshot) => {
        const article = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setArticle(article);
        setLoading(false);
        console.log(article);
      });
    }, []);
  
  return {article , loading}
}
