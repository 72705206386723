import React from "react";
import { useNavigate } from "react-router-dom";
import Loading from '../components/Loading';
import '../components/team/Team.css';
import TeamCompo from "../components/team/TeamCompo";
import advisor from '../data/Mawlana Rafiqul Isllam Pic for Web (2).jpg';
import teamHead from '../data/rabbani.png';
import transition from "../transition";
import useTeam from "../useContext/useTeam";



function Team() {

  const {team , loading} = useTeam();

  const navigate = useNavigate();

  const handleNavigate = () =>{
    navigate("/Rifat-Rabbani")
  }

  return (
  <div className="team-page">
    <div className="founder-cart">
        <div className="advisor-image">
          <img src={advisor} alt="" className="advisor" />
        </div>
        <div className="founder-content-team">
            <h1>Al-Hajj Mawlana Rafiqul Islam Anowary</h1>
            <h4 style={{textAlign:"center"}}>Chairman, Advisory Board <br /> Dars For Youth</h4>
        </div>
    </div>

    <div className="founder-cart">
        <div className="founder-image">
          <img src={teamHead} alt="" />
        </div>
        <div className="founder-content-team">
            <h1>Hafiz Rifat Mahmud Rabbani</h1>
            <h4>Founder, Dars For Youth</h4>
            <button onClick={handleNavigate}>About Founder</button>
        </div>
    </div>

    <div className="team-container">
      {loading && <Loading />}
      {
        team.map((value, index) =>
        <TeamCompo value={value} key={index} />
        )
      } 
    </div>
  </div>
  ); 
}

export default transition(Team)