import React, { useState } from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { IoMenu } from "react-icons/io5";
import { LuPhoneCall } from "react-icons/lu";
import { RiInstagramFill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import logo from "../data/2.png";
import NavbarModal from "./NavbarModal";

function Navbar() {
  // const [activeMenu, setActicveMenu] = useState("home");

  const [showModal, setShowModal] = useState(true);

  // const handleActiveMenu = (y) => {
  //   setActicveMenu(y);
  // };

  const handleMenuShow = () => {
    setShowModal(false);
    console.log("Clicked");
  };

  const handleMenuClose = () => {
    setShowModal(true);
  };

  return (
    <div className="navbar-main">
      <div className="navbar-main-details">
        <ul>
          <li>
            <a href="https://www.facebook.com/darsforyouth">
              <FaFacebookSquare />
            </a>
          </li>
          <li>
            <a href=" https://www.instagram.com/darsforyouth?igsh=MXRwdjA2NGhpdmFzNA==	">
              <RiInstagramFill />
            </a>
          </li>
          <li>
            <LuPhoneCall />
          </li>
          <li>
            <p>+8801304707662</p>
          </li>
        </ul>
        <div className="company-name">
          <h1>Dars For Youth</h1>
          <h2>بِسْمِ ٱللَّٰهِ ٱلرَّحْمَٰنِ ٱلرَّحِيمِ</h2>
        </div>
      </div>
      {/* MAIN NAVBAR MENU SECTION IS BELLOW */}
      <div className="nav-container-holder">
        <div className="nav-container">
          <NavLink
            to="/"
            className={({ isActive, isPending }) => (isActive ? "" : "")}
          >
            <div className="nav-logo-div">
              <img src={logo} alt="" />
            </div>
          </NavLink>

          <div className="nav-menu">
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    isActive ? "active" : ""
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/blogs"
                  className={({ isActive, isPending }) =>
                    isActive ? "active" : ""
                  }
                >
                  Blogs
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/programs"
                  className={({ isActive, isPending }) =>
                    isActive ? "active" : ""
                  }
                >
                  Programs
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/team"
                  className={({ isActive, isPending }) =>
                    isActive ? "active" : ""
                  }
                >
                  Our People
                </NavLink>
              </li>
              <li>
              <a
              href="https://forms.gle/Hku73dfVmtm5tTyv8"
              className="active"
              onClick={handleMenuClose}
              target="blank"
            >
              Join Us
            </a>
              </li>
            </ul>
          </div>

          <div className="menu-icon" onClick={handleMenuShow}>
            <p>
              <IoMenu />
            </p>
          </div>
        </div>
      </div>

      {showModal ? (
        <></>
      ) : (
        <div>
          <NavbarModal handleMenuClose={handleMenuClose} />
        </div>
      )}
    </div>
  );
}

export default Navbar;
