import { doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { db } from "../../firebaseConfigue";

export default function SingleBlog() {
  const { id } = useParams();
  const [singleBlog, setSingleBlog] = useState([]);
  const [loading, setLoading] = useState(true);

  const shareUrl = `https://darsforyouth.org/blogs/${id}`;

  useEffect(() => {
    const docRef = doc(db, "Blogs", id);
    onSnapshot(docRef, (snapshot) => {
      setSingleBlog({ ...snapshot.data(), id: snapshot.id });
      setLoading(false);
    });
  }, [id]);

  return (
    <div className="single-blog-container">
      <Helmet>
        <title>{singleBlog.cata || "Dars Blog Page"}</title>
        <meta property="og:image" content={singleBlog.imgUrl} />
        <meta property="og:url" content={singleBlog.imgUrl} />
      </Helmet>
      {loading && <h1>Loading...</h1>}
      <div className="single-blog-p">
        <p className="single-blog-w-name">{singleBlog.name}</p>
        <p>{singleBlog.cata}</p>
        <p>{singleBlog.desc}</p>
      </div>
      <div className="single-blog-share">
        <FacebookShareButton url={shareUrl} >
          <FacebookIcon size={30} round={true} />
        </FacebookShareButton>
        <WhatsappShareButton url={shareUrl} >
          <WhatsappIcon size={30} round={true} />
        </WhatsappShareButton>
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={30} round={true} />
        </LinkedinShareButton>
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={30} round={true} />
        </TwitterShareButton>
      </div>
      <div className="single-blog-image">
        <img src={singleBlog.imgUrl} alt="" />
      </div>
    </div>
  );
}
