import React from "react";
import { Link } from "react-router-dom";

import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { ImLinkedin } from "react-icons/im";

import { IoCall } from "react-icons/io5";

import { FaLocationDot } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

import { IoIosPeople } from "react-icons/io";
import logo from "../data/2.png";

export default function Footer() {
  return (
    <>
      <div className="footer-two">
        <div className="footer-upper-icon">
          <div className="footer-upper-icon-rounded">
            <IoCall />
          </div>
          <p>+8801304707662 </p>
        </div>

        <div className="footer-upper-icon">
          <div className="footer-upper-icon-rounded">
            <MdEmail />
          </div>
          <p>info@darsforyouth.org</p>
        </div>

        <div className="footer-upper-icon">
          <div className="footer-upper-icon-rounded">
            <FaLocationDot />
          </div>
          <p>Chattogram, Bangladesh</p>
        </div>
      </div>

      <div className="footer">
        <div>
          <img src={logo} alt="" className="footer-image" />
          <div className="footer-joinus">
              <a
                href="https://forms.gle/Hku73dfVmtm5tTyv8"
                // onClick={handleMenuClose}
                target="blank"
              >
                Join Us
              </a>
          </div>
        </div>

        <div>
          <div className="footer-contact-us">
            <h2>About Us</h2>
          </div>

          <div className="footer-contact-info">
            <div>
              <Link to="/">Home</Link>
            </div>
            <div>
              <Link to="/">Our Values</Link>
            </div>
            <div>
              <Link to="/team">Our People</Link>
            </div>
            <div>
              <Link to="/Rifat-Rabbani">About Founder</Link>
            </div>
          </div>
        </div>

        <div className="people-contact">
          <div className="it-support">
            <h1>People Of IT</h1>
            <div>
              <h2>Riad Mahmud Sirazy</h2>
              <h4>Director, IT</h4>
              <p>support@darsforyouth.org</p>
            </div>

            <div className="mahafuz">
              <h2>Jannatul Ferdaus</h2>
              <p>jannatul@darsforyouth.org</p>
            </div>

            <div className="mahafuz">
              <h2>Mahfuz Ahmed</h2>
              <p>mahafuz@darsforyouth.org</p>
            </div>
          </div>
        </div>

        <div className="important-links">
          {/* programs and blogs */}
          <h1>Resources</h1>
          <ul>
            <li>
              <Link to="/faq">Faq</Link>
            </li>
            <li>
              <a target="blank" href="https://forms.gle/mAvGqWYR14vcHqs7A">
                Feedback
              </a>
            </li>
            <li>
              <Link to="/programs">Programs</Link>
            </li>
            <li>
              <Link to="/blogs">Blogs</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer footer-last">
        <a
          href=" https://www.facebook.com/darsforyouth"
          className="footer-icon-rounded"
        >
          <FaFacebookSquare />
        </a>
        <a
          href=" https://www.instagram.com/darsforyouth?igsh=MXRwdjA2NGhpdmFzNA=="
          className="footer-icon-rounded"
        >
          <FaInstagramSquare />
        </a>
        <a
          href="https://www.linkedin.com/company/dars-bangladesh/"
          className="footer-icon-rounded"
        >
          <ImLinkedin />
        </a>
        <a
          href="https://www.facebook.com/groups/darsforyouthofficial"
          className="footer-icon-rounded"
        >
          <IoIosPeople />
        </a>
      </div>

      <div className="footer footer-last">
        <p>
          2024 ©️ ALL RIGHTS RESERVED BY DARS FOR YOUTH AND RIFAT MAHMUD RABBANI
        </p>
      </div>
    </>
  );
}
