import React from "react";

export default function Faq() {
  return (
    <div className="main-faq">
      <h1>Frequently Asked Questions </h1>
      <div className="qustion-ans">
        <details>
          <summary>When was Dars for Youth established?</summary>
          <p>
            Dars for Youth is a non-profit and non-political organization. It
            was founded in 2020 by Hafez Rifat Mahmud Rabbani. Our primary focus
            is on the skills and personality development of Madrasa Students.
          </p>
        </details>

        <details>
          <summary>What is your mission and vision?</summary>
          <p>
            Our mission: <br />
            We are dedicated to the advancement of Madrasa students by fostering
            the development of their skills and personalities. Through targeted
            initiatives and programs, we aim to empower them for a brighter
            future. <br />
            Our vision: <br /> <br />
            We envision a future where Madrasa students have access to a
            spectrum of skills and opportunities, allowing them to demonstrate
            their full potential on the international stage. By providing a
            well-rounded education, we aspire to equip them with the tools
            necessary to thrive in diverse global environments and contribute
            meaningfully to society. <br />
            <br />
            [Note] Our exclusive focus is on cultivating the skills and
            personalities of Madrasah students to ensure their holistic
            development. Throughout this journey, we steadfastly avoid promoting
            any specific ideology. Dars for Youth remains an inclusive platform,
            welcoming every young Madrasah student with open arms.
          </p>
        </details>

        <details>
          <summary>What projects do you have?</summary>
          <p>
            Our prominent project is the "Dars of Career," a distinctive
            initiative focused on the career development of Madrasa students,
            with two successful seasons completed. In addition, we provide
            various soft and hard skills to our members for continuous
            upskilling. It's important to note that currently, the majority of
            our activities are conducted through social media platforms.
          </p>
        </details>

        <details>
          <summary> What is the process to join Dars for Youth?</summary>
          <p>
            To become a part of Dars for Youth, please follow these steps:
            <br />
            <br />
            Step 1: Fill out our dedicated form available at the following link:{" "}
            <a
              href=" https://forms.gle/Hku73dfVmtm5tTyv8"
              className="text-indigo-500"
            >
              {" "}
              https://forms.gle/Hku73dfVmtm5tTyv8
            </a>
            <br />
            Step 2: Contact our <strong>Human Resource Officer, Mohammad Main Uddin</strong>, for
            further assistance. You can reach him through the following:
            <br />
            Mobile: +8801856831156
            <br />
            Email: mainuddin.dars@gmail.com
            <br />
            Please note that applicants may be required to undergo an interview
            round as part of the selection process.
            <br />
            <br />
            We look forward to welcoming you to our community!
          </p>
        </details>

        <details>
          <summary>Can I join and work if I am not a Madrasa student?</summary>
          <p>Absolutely, you are more than welcome to join and work with us.</p>
        </details>
      </div>
    </div>
  );
}
