import React from "react";

export default function EmpolyeeForm() {
  return (
    <div className="employeeForm">
      {/* <iframe 
        src='https://forms.gle/a7nw9TGcUCERPJ6dA'
        title="map"
        className="map"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        >
          Loading...
        </iframe> */}

      <div data-paperform-id="joindarsforyouth" data-takeover="1"></div>
    </div>
  );
}
