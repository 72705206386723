import React from "react";
import Blog from "../components/BlogComp/Blog";
import transition from "../transition";
import usePaginatedBlog from "../useContext/usePaginatedBlog";
import Loading from "../components/Loading";
import useTotalBlogs from "../useContext/useTotalBlogs";

function Blogs() {
  const { articles, loading, handleLoadMore } = usePaginatedBlog();

  const { totalArticle } = useTotalBlogs();

  return (
    <>
      <div className="blog-page">
        {/* <h1>Our Blogs</h1> */}
        <div className="blog-main">
          {loading && <Loading />}
          {articles.map((value, index) => (
            <Blog value={value} key={index} />
          ))}
        </div>
      </div>
      <div className="load-more-btn">
        {articles.length === totalArticle.length ? (
          articles.length < 10 ? (
            ""
          ) : (
            <button onClick={handleLoadMore}>
              {loading ? <Loading /> : "No More Blogs"}
            </button>
          )
        ) : (
          <button onClick={handleLoadMore}>
            {loading ? <Loading /> : "Watch More Blogs"}
          </button>
        )}
      </div>
    </>
  );
}

export default transition(Blogs);
