import React from "react";
import "./Team.css";

export default function TeamCompo(props) {
 
  const {name, designation,imgUrl, email} = props.value

  return (
    <div className="team-cart">
      <div className="team-image">
        <img src={imgUrl} alt="" />
      </div>
      <div className="team-content-team">
        <h1>{name}</h1>
        <h4>{designation}</h4>
        <p>{email}</p>
      </div>
    </div>
  );
}
