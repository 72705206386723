import React from "react";
import './Home.css'


export default function HomeComp() {
  return (
    <div className="our-story">
      <h1>Our Story</h1>
      <p>
        Welcome to Dars for Youth, a catalyst for change and a beacon of hope in
        the realm of possibilities. Founded by visionary Hafez Rifat Mahmud
        Rabbani, we transcend the ordinary, fueled by dedicated volunteers
        committed to propelling Madrasa Students towards greatness.  
        <br />
        At Dars for Youth, we are architects of dreams, sparking change and shaping a
        community of confident individuals prepared not only for their nation
        but for the world. 
        <br />
        Our mission is to uplift, inspire, and equip students
        with the tools for their personal revolution. The values of Integrity,
        Innovation, Impact, and Empowerment form the foundation of our journey.
        Join us in empowering the Madrasa community, as we collectively forge a
        brighter, bolder world, one fearless step at a time. Together, let's
        bring change and revolution.
      </p>
    </div>
  );
}
