import React from "react";
import { motion } from "framer-motion";
export default function Mission() {
  return (
    <div className="mission-container">
      <motion.div
        className="mission-card"
        initial={{ opacity: 0, scale: 0.5, x: -100 }}
        whileInView={{ opacity: 1, scale: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.3 }}
        viewport={{ once: true }}
      >
        <div className="mission-tittle">
          <h1>Our Mission</h1>
        </div>

        <div className="mission-p">
          <p>
            Dars for Youth passionately commits to the transformative mission of
            empowering the Madrasa Community. Through the cultivation of both
            soft and hard skills, we are dedicated to shaping resilient
            individuals who not only excel personally but also contribute
            meaningfully to our collective journey. Our mission is to ignite a
            spirit of empowerment, fostering the development of a skilled and
            confident Madrasa Community poised for impactful change.
          </p>
        </div>
      </motion.div>

      <motion.div
        className="mission-card"
        initial={{ opacity: 0, scale: 0, x: 100 }}
        whileInView={{ opacity: 1, scale: 1, x: 0 }}
        transition={{ duration: 0.9, delay: 0.3 }}
        viewport={{ once: true }}
      >
        <div className="mission-tittle">
          <h1>Our Vision</h1>
        </div>
        <div className="mission-p">
          <p>
            In the radiant tapestry of our vision, Dars for Youth sees a future
            where every member of the Madrasa Community radiates with essential
            life skills. We dream of a harmonious integration, breaking barriers
            to empower individuals to collaborate seamlessly across society.
            Beyond borders, our vision extends to creating global citizens,
            providing not just jobs and entrepreneurship opportunities but
            unlocking a world of possibilities. Join us in crafting a narrative
            where empowerment transcends limits, and together, we shape a future
            of boundless potential.
          </p>
        </div>
      </motion.div>
    </div>
  );
}
