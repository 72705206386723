import React from "react";
import { Link } from "react-router-dom";
import useBlog from "../../useContext/useBlog";
import Loading from "../Loading";

export default function HomeBlogs() {
  const { article, loading } = useBlog();



  return (
    <>
      <div className="home-blogs-headers">
        <h1>Our Recent Blogs</h1>
      </div>
      <div className="home-blog-container">
        {loading && <Loading />}
        {article.map((value, index) => (
          <div className="home-blog-card" key={index}>
            <p> {value.createdAt.toDate().toDateString()}</p>
            <div className="home-blog-content">
              <p>{value.desc.slice(0, 200)}</p>
              <Link to={`/blogs/${value.id}`}>
                <button>View</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
