import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import founder from "../../data/Web Pic.png";
import "../homes/OtherHome.css";

export default function DarsFounder() {
  return (
    <div>
      <div className="founder-msg-container">
        {/* image thakbe ei pashe */}
        <div className="founderImage-container">
          <div className="founder-image-border">
            <div className="founder-img">
              <img src={founder} alt="" />
            </div>
          </div>
          <h1>Hafiz Rifat Mahmud Rabbani</h1>
          <div className="blue-theme-bg">
            <h3>Founder, Dars For Youth</h3>
          </div>
        </div>
        {/* Content thakbe ei pashe */}
        <div className="founder-content">
          <h3>About Founder</h3>
          <div>
            <p className="fouder-message">
              <strong>Hafiz Rifat Mahmud Rabbani:
                A Journey of Resilience and Inspiration </strong>
              <br></br>
              Starting from a place of strong determination and big dreams,
              Hafiz Rifat Mahmud Rabbani has tirelessly worked to make a
              positive impact on Madrasa students through his organization, Dars
              for Youth. His story is one of facing challenges with resilience,
              showing leadership, and staying committed to his goals.
              <br></br> <br></br>
              <strong>Dreaming Big: </strong>
              <br></br>
              Growing up in Madrasa, Hafiz Rabbani's childhood was filled with a
              strong desire to improve the lives of Madrasa students. Seeing the
              differences in education, lifestyle, and opportunities, he decided
              to work towards making positive changes.
              <br></br> <br></br>
              <strong>Understanding the Challenges: </strong>
              <br></br>
              Working with various organizations made Hafiz Rabbani realize that
              he understood the challenges faced by Madrasa students better than
              anyone else. This led him to create Dars for Youth, a unique
              organization aimed at providing specific support to Madrasa
              students.
              <br></br> <br></br>
              <strong>Inspiring Others: </strong>
              <br></br>
              Rabbani's journey is filled with both successes and setbacks, but
              he's emerged as an inspiring figure. As a Hafiz-E-Quran,
              entrepreneur, and lifelong learner, he serves as a symbol of
              resilience, always bouncing back stronger after facing
              difficulties.
              <br></br> <br></br>
              <strong>Educational Journey: </strong>
              <br></br>
              Starting with memorizing The Holy Quran at 14, Hafiz Rabbani's
              beautiful recitation not only won many awards but also reflected
              his deep spiritual connection. Currently studying English at
              Premier University, Chittagong, and religious studies at
              Madrasa-E-Tayabia Islamia Sunnia Fazil, he perfectly blends
              traditional and modern education.
              <br></br> <br></br>
              <strong>Professional Path: </strong>
              <br></br>
              Beginning his professional journey at the college level, Hafiz
              Rabbani took on various roles. From a Junior Executive at Lead
              Life to impactful positions at Bangladesh Youth Leadership Center
              (BYLC), where he excelled in ‘Admissions & Operations’ team and
              later joined ‘Marketing & Communications’ team as a Campus
              Ambassador, his journey includes roles at Lead Bangladesh, where
              he currently serves as a Copywriter and a Team Lead of their
              internship programs, guiding many young professionals.
              <br></br>  <br></br>
              <strong>Leadership Initiatives: </strong>
              <br></br>
              Starting his leadership journey at 17, Hafiz Rabbani founded
              transformative organizations like EdTalk, Rabbani Tours, BakEat,
              and BookEd. In 2020, he founded Dars for Youth, focusing on
              empowering Madrasa students. Studying leadership at BYLC in the
              Harvard Curriculum, his influence extended to roles like Institute
              Representative for Volunteer for Bangladesh (VBD), Campus
              Ambassador at Rotaract Club of Islamabad, and a participant in
              International MUN. His leadership roles include being a CM in the
              department of SDG, Community & Youth Development at 64D
              Initiatives, Captain and an associate at Team Chittagong., Campus
              Ambassador at Active Bangladesh, Executive at HH Foundation, and
              an Active Member of the Joy Bangla Award-winning organization Ek
              Takay Shikkha.
              <br></br> <br></br>
              <strong>Cultural Impact:</strong>
              <br></br>
              Immersed in cultural excellence, Hafiz Rabbani is a proud student
              of Zila Shilpakala Academy, Chattogram, focusing on the art of
              recitation. His melodious rendition of Nazrul Geet earned him the
              National Education Week Award at the thana level, a prestigious
              government accolade. As a lead artist at Proma Abritti Sangathan,
              Rabbani's cultural impact is evident through his leadership and
              artistic expression. Hafiz Rifat Mahmud Rabbani's life unfolds as
              a story of resilience and commitment to making a positive impact.
              His journey serves as a source of motivation, encouraging others
              to dream big and persist in creating positive change.
            </p>
            <div className="founder-link">
              <p>
                <a target="blank" href="https://www.facebook.com/TheRabbaniX">
                  <FaFacebookSquare />
                </a>
              </p>
              <p>
                <a
                  target="blank"
                  href=" https://www.instagram.com/shahzada_rabbanii?igsh=MWNneTR5ZzgxenY2cQ=="
                >
                  <RiInstagramFill />
                </a>
              </p>
              <p>
                <a
                  target="blank"
                  href="https://www.linkedin.com/in/therabbanix	"
                >
                  <IoLogoLinkedin />
                </a>
              </p>
              <p className="founder-mail">
                <MdEmail />rabbani@darsforyouth.org
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
