import { motion } from "framer-motion";
import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import founder from "../../data/rabbani.png";
import "./OtherHome.css";

export default function FounderMsg() {
  return (
    <div className="founder-msg-container">
      {/* image thakbe ei pashe */}
      <div className="founderImage-container">
        <motion.div
          className="founder-image-border"
          initial={{ opacity: 0, scale: 0.5 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
        >
          <div className="founder-img">
            <img src={founder} alt="" />
          </div>
        </motion.div>
        <h1>Hafiz Rifat Mahmud Rabbani</h1>
        <div className="blue-theme-bg">
          <h3>Founder, Dars For Youth</h3>
        </div>
        <div className="founder-link-home">
          <p>
            <a target="blank" href="https://www.facebook.com/TheRabbaniX">
            <FaFacebookSquare />
            </a>
          </p>
          <p>
            <a
              target="blank"
              href=" https://www.instagram.com/shahzada_rabbanii?igsh=MWNneTR5ZzgxenY2cQ=="
            >
              <RiInstagramFill />
            </a>
          </p>
          <p>
            <a target="blank" href="https://www.linkedin.com/in/therabbanix	">
              <IoLogoLinkedin />
            </a>
          </p>
        </div>
      </div>
      {/* Content thakbe ei pashe */}
      <div className="founder-content">
        <h3>Founder’s Message</h3>
        <div>
          <p className="fouder-message">
            Growing up in the sacred halls of Madrasa, my educational journey
            began with a deep connection to religious studies. As a Hafiz e
            Quran, I've experienced the challenges faced by Madrasa students who
            often lack the opportunities available to their peers in traditional
            schools and colleges. Society has created a gap, wrongly assuming
            that Madrasa students cannot match the societal standards or
            contribute beyond religious realms. I firmly believe in the immense
            potential residing within every Madrasa student—potential that
            extends to leadership, law, medicine, engineering, politics, and
            more. What they lack is proper mentorship and opportunities. This
            belief birthed "Dars for Youth," a platform dedicated to closing the
            gap between Madrasa students and society. Our mission is clear: to
            equip Madrasa students with the skills and opportunities they
            deserve, erasing misconceptions and empowering them to become
            dreamers and achievers. Join us on this transformative journey as we
            illuminate paths and inspire a future where every Madrasa student
            shines brightly.
          </p>
        </div>
      </div>
    </div>
  );
}
