import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../firebaseConfigue';

export default function useTeam() {
    const [team, setTeam] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const articleRef = collection(db, "Team");
      const q = query(articleRef, orderBy("createdAt", "desc"));
      onSnapshot(q, (snapshot) => {
        const article = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTeam(article);
        setLoading(false);
      });
    }, []);

  return {team , loading}
}
